import React from "react";
import RedDotTitle from "../../../components/RedDotTitle";

import './sectionThird.scss';

const STEP_1 = 'Cree su cuenta';
const STEP_2 = 'Complete los datos';
const STEP_3 = 'Ingrese su Cuenta bancaria';
const STEP_4 = 'Disfrute el dinero';

const SectionThird = () => {
  return (
    <div className="section-third">
      <RedDotTitle className="title" dotSize="large">¿Cómo funciona?</RedDotTitle>
      {
        isMobile ?
          <>
            <div className="row-1">
              <div className="pic-1">
                <i className="icon"/>
                <p>{STEP_1}</p>
              </div>
              <div className="arrow"/>
              <div className="pic-2">
                <i className="icon"/>
                <p>{STEP_2}</p>
              </div>
            </div>
            <div className="row-2">
              <div className="arrow"/>
            </div>
            <div className="row-3">
              <div className="pic-1">
                <i className="icon"/>
                <p>{STEP_4}</p>
              </div>
              <div className="arrow"/>
              <div className="pic-2">
                <i className="icon"/>
                <p>{STEP_3}</p>
              </div>
            </div>
          </> :
          <>
            <div className="first">
              <div className="pic-1">
                <i className="icon"/>
                <p>{STEP_1}</p>
              </div>
              <div className="arrow"/>
              <div className="pic-2">
                <i className="icon"/>
                <p>{STEP_3}</p>
              </div>
            </div>
            <div className="second">
              <div className="arrow-1"/>
              <div className="pic-1">
                <i className="icon"/>
                <p>{STEP_2}</p>
              </div>
              <div className="arrow-2"/>
              <div className="pic-2">
                <i className="icon"/>
                <p>{STEP_4}</p>
              </div>
            </div>
          </>
      }
    </div>

  )
}
export default SectionThird;