import React, { useEffect } from "react";
import {scrollToTop} from "../../utils";

const Privacy = () => {
  useEffect(() => {
    scrollToTop()
  }, []);
  return (
    <div style={{fontSize: 16, padding: 10}}>
      <p style={{fontSize: 28, fontWeight: 500}}>Política de privacidad</p>
      <p>Esta Política de privacidad describe las políticas y los procedimientos que sigue Holaya en la recopilación, el uso y la divulgación de su información y otros datos cuando se registra para usar nuestras aplicaciones y servicios.</p>
      <p>Respetamos su privacidad y estamos comprometidos a proteger su privacidad al cumplir con esta política de privacidad ("Política").</p>
      <p>Esta política describe los tipos de información que podemos recopilar de usted o que usted puede proporcionar en la aplicación móvil "Holaya" ("aplicación móvil" o "servicio") y cualquier producto y servicio relacionado ("información personal") ("Información personal") denominados colectivamente como "Servicios"), y nuestras prácticas de recopilación, uso, mantenimiento, protección y divulgación de esa información personal.</p>
      <p>También describe las opciones disponibles con respecto a nuestro uso de su información personal y cómo puede acceder a ella y actualizarla.</p>
      <p>SMS</p>
      <p>Recopilaremos todos sus datos SMS, pero solo monitoreamos sus mensajes relacionados con transacciones financieras. Para ser específicos, monitorearemos el nombre del remitente, la descripción y el monto de la transacción para realizar la evaluación del riesgo crediticio. Lo anterior nos permite identificarte adecuadamente y verificar si calificas como persona a ser financiada por nosotros. Subiremos los datos de SMS al servidor de Holaya (https://gateway.holaya.net) a través de una conexión segura.</p>
      <p>Ubicaciones</p>
      <p>Recopilamos y monitoreamos la información acerca de las ubicaciones de sus dispositivos para la evaluación del perfil del cliente y para evitar fraudes. Subiremos los datos de ubicaciones al servidor de Holaya (https://gateway.holaya.net).</p>
      <p>Celular:</p>
      <p>Recopilamos y monitoreamos información acerca de su dispositivo, incluyendo nombre, modelo, región, lengua, código de identificación, información de hardware y software, estatus, hábitos de uso, identificadores únicos como IMEI y números seriales. Dicha información nos es útil para identificar el dispositivo desde donde proviene la solicitud de préstamo y otras adicionales, para evaluar adecuadamente tu perfil y evitar fraudes.</p>
      <p>Además, recogeremos tu lista detallada de aplicaciones instaladas para evaluar tu propensión al préstamo y tu situación de deuda. Subiremos los datos de celular al servidor de Holaya (https://gateway.holaya.net).</p>
      <p>Cámara:</p>
      <p>Se pedirá acceso a la cámara del dispositivo móvil para que puedas identificarte por medio de un “selfie” y además puedas proporcionarnos los documentos necesarios para el proceso de solicitud y evaluación de tu perfil de cliente. Subiremos los datos de cámara al servidor de Holaya (https://gateway.holaya.net).</p>
      <p>Calendario:</p>
      <p>Solicitaremos acceso a su calendario, para poder agendar la fecha de pago respectiva y los recordatorios respectivos. De esta manera, gestionamos nuestro riesgo de crédito y evitamos pagos vencidos. Subiremos los datos de calendario al servidor de Holaya (https://gateway.holaya.net).</p>
      <p>Aplicaciones instaladas：</p>
      <p>Con el fin de evaluar la elegibilidad del cliente parasolicitar un prestamo, es necesario recopilarinformacion sobre la actividad en aplicaciones. Estainformacion incluye el nombre del paquete, el nombrede la aplicacion, la hora de la primera instalacion y lahora de actualizacion de la aplicacion. Toda estainformacion se cargar de forma encriptada ennuestro servidor seguro ubicado en  (https://gateway.holaya.net). Utilizamos estos datos para identificary analizar el comportamiento y el riesgo asociado conmultiples prestamos, con el objetivo de determinar sise puede procesar una solicitud de prestamo yprevenir el fraude.Le garantizamos que sus datos permanecerán confidenciales y no serán compartidos con terceros en ningún momento.</p>
      <p>Datos de Contactos：</p>
      <p>Necesitamos que seleccione manualmente el contactos de emergencia desde su lista de contactos incluido nombre y número de teléfono, esta función sirve para cuando no podemos poner en contacto contigo. Solamente los contactos seleccionados manualmente por los usuarios se transmitirán a nuestra aplicación de manera encriptada, y se guardarán por 1 año a partir de la fecha de carga. Nunca recopilaremos su información sin consentimiento. Si desea eliminar sus datos privados, puede enviar un correo electrónico a holayaapp@gmail.com. Envíe una solicitud al servicio de atención al cliente y podemos eliminarla manualmente dentro de (3-5 días hábiles).</p>
      <p>Información personal del usuario</p>
      <p>Para realizar control de riesgo y evaluar la elegibilidad del usuario, necesitamos usar las informaciones personales incluido nombre completo, estado civil, género, fecha de nacimiento, edad, país, identificación oficial vigente, documento nacional de identidad (DNI), correo electrónico.</p>
      <p>Se va realizar el  depósito bancario directamente al número de cuenta proporcionados por los usuarios.</p>
      <p>El cooperador</p>
      <p>El SDK que utilizamos para conectar con el servicio de proveedor de Deeplink se entrega a través de múltiples canales (por ejemplo, Google, Facebook) con el fin de distinguir la fuente de atribución del canal del usuario y transmitir la identidad de cada canal, pero nuestro socio Deeplink no accede ni utiliza ninguna información de la cuenta social del usuario, tampoco nuestra propia app accede ni utiliza ninguna información de la cuenta social del usuario.</p>
      <p>Por qué usamos estos datos:</p>
      <p>Para procesamiento y autenticación de préstamos;</p>
      <p>Para habilitar la atención al cliente;</p>
      <p>Para investigación y desarrollo;</p>
      <p>Para las comunicaciones entre usted y nosotros;</p>
      <p>Para marketing y divulgación; Para la toma de decisiones automatizada;</p>
      <p>Para el cumplimiento y los requisitos legales.</p>
      <p>CÓMO UTILIZAMOS SUS DATOS PERSONALES Y PARA QUÉ FINES</p>
      <p>Solo utilizaremos sus datos personales de acuerdo con la ley aplicable. En la mayoría de los casos, utilizaremos sus datos personales para prestarle los Servicios, o cuando necesitemos cumplir con una obligación legal.</p>
      <p>Usted acepta y reconoce que al usar nuestros Servicios y crear una cuenta en la Aplicación, nos autoriza a nosotros y a nuestros socios asociados y afiliados a contactarlo por correo electrónico, teléfono o de otra manera. Esto es para garantizar que conozca todas las características de los Servicios.</p>
      <p>En general, utilizamos sus datos personales para los siguientes propósitos y actividades realizadas sin supervisión o control humano directo:</p>
      <p>Para registrarlo como usuario de la Aplicación;</p>
      <p>Para proporcionarle los Servicios;</p>
      <p>Para administrar nuestra relación con usted, incluida la notificación de cambios en cualquier Servicio;</p>
      <p>Para administrar y proteger nuestro negocio y la Aplicación, incluida la resolución de problemas, el análisis de datos, las pruebas del sistema y la realización de operaciones internas;</p>
      <p>Para proporcionarle contenido;</p>
      <p>Para monitorear tendencias para que podamos mejorar la Aplicación y los Servicios;</p>
      <p>Para mejorar nuestros modelos comerciales y de entrega;</p>
      <p>Para cumplir con nuestras obligaciones en virtud del acuerdo que estamos a punto de celebrar o hemos celebrado con usted;</p>
      <p>Para hacer cumplir nuestros Términos;</p>
      <p>Para responder a órdenes judiciales, establecer o ejercer nuestros derechos legales, o defenderse de reclamaciones legales.</p>
      <p>Contáctenos</p>
      <p>Si tiene alguna pregunta sobre nuestra política de privacidad o productos mientras navega por la Política de privacidad, contáctenos directamente.</p>
      <p>Correo electrónico：holayaapp@gmail.com</p>
      <p>Dirección: Miraflores, Lima, Perú</p>
    </div>
  )
}

export default Privacy;