import React from "react";
import RedDotTitle from "../../../components/RedDotTitle";
import { Link } from 'react-router-dom';
import { EMAIL, TIME, APP_LINK, WHATS_APP_LINK, WHATS_APP} from "../../../utils";
import './sectionFirst.scss';

const SectionFirst = () => {
  return (
    <div className="section-first">
      <div className="contact">
        <div className="email">
          <i className="icon"/>
          <p className="content">{EMAIL}</p>
        </div>
        <div className="time">
          <i className="icon"/>
          <p className="content">{TIME}</p>
        </div>
      </div>
      <div className="header">
        <div className="logo"/>
        <div className="login">
          {/*<p className="text" onClick={onScrollTop}>Inicio</p>*/}
          <div className="login-contact">
            <div className="whatsapp">
              <i className="icon"/>
              <Link className="content" to={WHATS_APP_LINK}>{WHATS_APP}</Link>
            </div>
            {/*<div className="phone">*/}
            {/*  <i className="icon"/>*/}
            {/*  {*/}
            {/*    isMobile ?*/}
            {/*      <a href={`tel:${PHONE}`} className="content">{PHONE}</a> :*/}
            {/*      <p className="content">{PHONE}</p>*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="main">
        <RedDotTitle className="title" dotSize="large">¿Necesita efectivo al instante?</RedDotTitle>
        <p className="sub-title">Solicita y obtén un préstamo personal y seguro hasta S/ 5,000.</p>
        <a href={APP_LINK}>
          <div className="button">Aplica ya</div>
        </a>
      </div>
    </div>
  )
}

export default SectionFirst;
