import React from "react";
import RedDotTitle from "../../../components/RedDotTitle";

import './sectionSecond.scss';


const data = [
  {
    title: 'Seguro',
    subTitle: 'Solicita y obtén un préstamo personal y seguro hasta S/ 5,000.',
    icon: 'icon1'
  },
  {
    title: 'Monto alto',
    subTitle: 'Fácil de aumentar el monto',
    icon: 'icon2'
  },
  {
    title: 'Largo plazo',
    subTitle: 'Hasta 180 días',
    icon: 'icon3'
  },
  {
    title: 'Rápido',
    subTitle: 'Depósito a su cuenta en instantes',
    icon: 'icon4'
  },
]

const SectionSecond = () => {
  return (
    <div className="section-second">
      <RedDotTitle className="title" dotSize="large">¿Por qué elegir un préstamo en Holaya?</RedDotTitle>
      <div className="list">
        {
          data.map(item => (
            <div className="rect" key={item.icon}>
              {
                isMobile ? <>
                  <div className="header">
                    <i className={`icon ${item.icon}`}/>
                    <RedDotTitle className="rect-title">{item.title}</RedDotTitle>
                  </div>
                  <p className="rect-sub-title">{item.subTitle}</p>
                </> :
                  <>
                    <i className={`icon ${item.icon}`}/>
                    <div>
                      <RedDotTitle className="rect-title">{item.title}</RedDotTitle>
                      <p className="rect-sub-title">{item.subTitle}</p>
                    </div>
                  </>
              }
            </div>
          ))
        }
      </div>
      <div className="phone" />
    </div>
  )
}

export default SectionSecond;