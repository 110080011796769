import React from "react";

import './index.scss';

interface IProps {
  children?: React.ReactElement | string
  dotSize?: 'large' | 'small'
  className?: string
  style?: React.CSSProperties
}

const RedDotTitle = (props: IProps) => {
  const { children, className, style, dotSize = 'small' } = props;
  return (
    <p className={`red-dot-title${className ? ` ${className}` : ''} ${dotSize}`} style={style}>
      <span className="title-inner">
        {children}
      </span>
    </p>
  )
}

export default RedDotTitle;