import React, { useEffect } from "react";
import {scrollToTop} from "../../utils";

const Agreement = () => {

  useEffect(() => {
    scrollToTop()
  }, []);

  return (
    <div style={{fontSize: 16, padding: 10}}>
      <p style={{fontSize: 28, fontWeight: 500}}>Acuerdo de usuario</p>
      <p>I. ACEPTACIÓN Y ALCANCE DE LOS TÉRMINOS Y CONDICIONES.</p>
      <p>Estos términos y condiciones de uso, en adelante “Términos”, son aplicables para el uso de los servicios ofrecidos por Holaya (en adelante “Amigo“) a través de la aplicación para Dispositivos Móviles (en adelante “Aplicación”, “App”) o de su página web https://www.holaya.net/ (en adelante “Página Web”).</p>
      <p>El usuario entiende y acepta que al ingresar y navegar en el Sitio de Holaya y/o al registrarse y utilizar su App y/o solicitar algún servicio, manifiesta de forma indubitable su voluntad plena y sin reservas de quedar sujeto a los presentes Términos y Condiciones, los cuales tienen carácter obligatorio y vinculante.</p>
      <p>Asimismo, el Usuario queda sujeto a los términos del Aviso de Privacidad (https://www.holaya.net/#/privacy) y las Políticas que rigen a Holaya, mismos que también se encuentran a su disposición, por lo que, en caso de no aceptar o estar de acuerdo con estos términos y condiciones, o cualquier documento contenido en el sitio, deberá abstenerse de continuar navegando en el Sitio de Holaya, así como de utilizar la App citada.</p>
      <p>En este sentido es responsabilidad del Usuario leer y entender íntegramente las condiciones establecidas a continuación, antes de realizar cualquier gestión dentro del Sitio y/o de registrarse y solicitar cualquier servicio en la App.</p>
      <p>Se hace la puntal aclaración que los productos y servicios que ofrece Holaya, están dirigidos única y exclusivamente a personas naturales que cuenten con capacidad legal para contratar, dando como conclusión que expresamente están excluidos las personas que sean menores de edad, las aquellas declaradas judicialmente como en estado de interdicción u aquellas limitadas por cualquier otro contrato, convenio o relación comercial, estos siempre se entenderá de manera enunciativa más no limitativamente.</p>
      <p>El usuario entiende y acepta que la aceptación mencionada implica la autorización para que Holaya acceda a su dispositivo móvil, cámara, etc.</p>
      <p>II. DEFINICIONES</p>
      <p>(i) “Usuario”: Toda aquella persona que acceda, mediante un Dispositivo Móvil a la Aplicación y/o a la Página Web</p>
      <p>(ii) “Dispositivo Móvil”: Todo teléfono móvil, tarjeta SIM, equipo de cómputo, tableta y/o cualquier otro dispositivo con acceso a Internet que le permita acceder a la Página Web y/o usar la Aplicación;</p>
      <p>(iii) “Préstamo”: Servicio crediticio solicitado por el Usuario y otorgado por Holaya a cambio de una retribución monetaria indicada y aceptada previo a la solicitud del usuario, bajo las condiciones del Contrato de Mutuo Dinerario que se encuentra en el Anexo 1.</p>
      <p>(iv) “Cuenta”: significa su cuenta individual que le permite al Usuario acceder al Sistema y utilizar el Servicio;</p>
      <p>(v) Página Web: https://www.holaya.net</p>
      <p>(vi) “Algoritmo crediticio”: Software y procedimientos de propiedad de Holaya para tomar decisiones de los servicios de préstamo brindados.</p>
      <p>III. REGISTRO/CREACIÓN, INHABILITACIÓN Y CANCELACIÓN DE CUENTA.</p>
      <p>El Usuario garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados. Holaya podrá utilizar diversos medios para identificar a sus Usuarios, pero Holaya no se responsabiliza por la veracidad de los Datos Personales provistos por sus Usuarios. Holaya se reserva el derecho de solicitar algún comprobante adicional con el fin de corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.</p>
      <p>Al realizar el registro, el Usuario autoriza a que Holaya comparta información de uso y comportamiento crediticio con los contactos que el usuario tiene guardados en su dispositivo que también utilizan la aplicación.</p>
      <p>El Usuario se obliga a mantener la confidencialidad de su Contraseña, pues entiende que será responsable por todas las operaciones efectuadas en y desde su Cuenta, toda vez que el acceso a la misma está restringido al ingreso y uso de su contraseña, la cual es de su conocimiento exclusivo.</p>
      <p>Inhabilitación de la Cuenta. Sin perjuicio de otras medidas, Holaya podrá suspender, sin requisito o formalidad alguna, en forma temporal o inhabilitar definitivamente la cuenta del usuario y/o suspender la prestación de sus servicios si el usuario:</p>
      <p>(a) Trasgrediera alguna ley, o cualquiera de las estipulaciones de los presentes Términos y Condiciones.</p>
      <p>(b) Incumpliera sus compromisos como usuario.</p>
      <p>(c) Incurriera a criterio de Holaya en conductas o actos fraudulentos.</p>
      <p>(d) No pudiera verificarse la identidad del usuario o cualquier información proporcionada por el mismo fuere errónea o falsa.</p>
      <p>Cancelación de cuenta. El Usuario podrá cancelar en cualquier momento su Cuenta, siempre y cuando no exista alguna obligación de pago pendiente a favor de Holaya.</p>
      <p>IV. DATOS PERSONALES.</p>
      <p>El Usuario deberá llenar todos y cada uno de los campos en donde proporcionará los datos de identificación del cliente o usuario, como lo son el nombre, apellido, fecha de nacimiento, dirección de domicilio, número telefónico de contacto, nombre y números de referencias de contactos, número de cuenta y/o clave interbancaria en donde se depositara los recursos en dado caso de que sea autorizado el Préstamo que solicitó el Usuario, en este acto de la misma manera el mismo usuario otorga su pleno consentimiento a Holaya, para que se de uso y tratamiento de sus datos personales, tal y como de la misma manera se referencia en el aviso de privacidad que pone a disposición del público en general a través de la página web de Holaya.</p>
      <p>Asimismo, el Usuario a través de estos términos y condiciones autoriza expresamente a Holaya, para que lleve a cabo investigaciones sobre el comportamiento crediticio en las sociedades de información crediticia (SIC) que estime conveniente.</p>
      <p>Por lo cual conoce la naturaleza y alcance de la información que se solicitará, del uso que se le dará y que se podrá realizar consultas periódicas del historial crediticio.</p>
      <p>Es así que a través de la aceptación de estos términos y condiciones tendrán una vigencia de 5 años contados a partir de la aceptación de los mismos u en su caso mientras subsista la relación jurídica, por lo cual el usuario acepta que dicho documento quede en propiedad de Holaya para efectos de control y cumplimiento.</p>
      <p>V. CONFIRMACIÓN DE USUARIO Y RESTRICCIONES.</p>
      <p>Una vez ingresados los datos requeridos, el usuario recibirá un número de confirmación al número de teléfono celular que indicó en la Aplicación y/o página web, esto como parte del proceso para la autenticación de su identidad, dicha confirmación deberá ingresar en la Aplicación y o Pagina web para concluir con el registro. El envío de dichos códigos se entenderá por parte de Holaya que este fue realizado por solicitud del Usuario.</p>
      <p>De la misma manera se le solicitará al Usuario que dentro del mismo proceso, realice una fotografía de su identificación por el anverso y el reverso de la misma, lo anterior es necesario para comprobar por parte de Holaya que el Usuario es mayor de edad y puede celebrar cualquier tipo de contrato no importando la naturaleza de este, subsecuentemente se le solicitara que el Usuario se tome una fotografía tipo Selfie, esto es para comprobar de la misma manera por parte de Holaya que el Usuario que registró su Cuenta es la misma persona que está solicitando el Préstamo y que posee la mayoría de edad.</p>
      <p>Holaya se reserva el derecho de solicitar documentación adicional con el fin de corroborar los datos ingresados en la solicitud, así como de suspender temporal o definitivamente las cuentas y/o negar las solicitudes de aquellos usuarios cuyos datos no hayan podido ser confirmados, sean detectados como alterados o falsificados.</p>
      <p>La cuenta dentro de la Aplicación o en la página web es de carácter personal, única e intransferible, por lo cual, Holaya le tiene terminantemente prohibido al titular de la Cuenta que un mismo Usuario inscriba o posea más de una Cuenta dentro de la página o dentro de la Aplicación. En caso de que Holaya detecte distintas cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas, sin responsabilidad alguna.</p>
      <p>El Usuario se compromete a notificar a Holaya en forma inmediata, cualquier uso no autorizado de su cuenta, así como el ingreso por terceros no autorizados a la misma, destacando que está prohibida la venta, cesión o transferencia de la cuenta.</p>
      <p>El Usuario podrá cancelar en cualquier momento su cuenta, siempre y cuando no exista alguna obligación de pago pendiente a favor de Holaya.</p>
      <p>Holaya no se hará responsable, por errores generales o tipográficos en los cuales incurra el Usuario o por negligencia al usar los servicios de la App y/o el Sitio, de igual forma Holaya se exime de responsabilidad ante negligencia al dejar cualquier dispositivo electrónico, mediante el cual tenga acceso a la App, el Sitio y los servicios, sin atención alguna, reservándose el derecho de rechazar cualquier solicitud de registro o de cancelar una solicitud previamente aceptada, sin que esté obligado a exponer las razones de su decisión y sin que ello genere algún derecho a indemnización al Usuario.</p>
      <p>VI. LIMITACIONES DE LA RESPONSABILIDAD.</p>
      <p>El Usuario declara conocer expresamente que la Aplicación y la Página Web requieren de permisos especiales para funcionar en el Dispositivo Móvil, y que Holaya no será responsable por los efectos que pudiera generar la revocación y/o intento de revocación de aquellos permisos especiales. El Usuario desde el momento en que instala la Aplicación renuncia al derecho de imponer cualquier reclamo al respecto.</p>
      <p>El Usuario acepta que es responsable de contar con software y hardware compatible con la Aplicación y/o con la Página Web, los dispositivos necesarios, conexión a la red de Internet, y cualquier otro requerimiento técnico necesario para la correcta ejecución de la Aplicación y/o de la Página Web.</p>
      <p>Dado que la Aplicación se encuentra en un proceso de mejora continua y constante actualización Holaya no puede garantizar que la misma esté disponible sin interrupciones y que siempre esté libre de errores, y por tanto, no se responsabiliza por posibles daños y perjuicios causados al Usuario por este concepto.</p>
      <p>VII. Inhabilitación de la Cuenta y Suspensión del Servicio</p>
      <p>Sin perjuicio de otras medidas, Holaya podrá suspender, sin requisito o formalidad alguna, en forma temporal o inhabilitar definitivamentela Cuenta del Usuario y/o suspender la prestación de sus servicios si:</p>
      <p>(a) se trasgrediera alguna ley, o cualquiera de las estipulaciones de los presentesTérminos y Condiciones y demás políticas de Holaya,</p>
      <p>(b) si incumpliera sus compromisos como Usuario,</p>
      <p>(c) si se incurriera a criterio de Holaya en conductas o actos fraudulentos, y</p>
      <p>(d) si no pudiera verificarse la identidad del Usuario o cualquier información proporcionada por el mismo fuere errónea o falsa.</p>
      <p>VIII. SOLICITUD DE PRÉSTAMO.</p>
      <p>Una vez creada la Cuenta por parte del Usuario este podrá dentro de la Aplicación o la Página Web solicitar el Préstamo hasta por un máximo de S/ 5,000.00 (CINCO MIL SOLES), pero como primer punto en caso de que el Usuario pueda ser considerado por Holaya como sujeto a otorgamiento de Préstamo por la cantidad máxima, el mismo Usuario deberá generar un historial con Holaya como cliente que cumple con el pago de sus préstamos, es así que únicamente se analizara la información vertida por el Usuario y en un primer momento se podrá autorizar el monto mínimo o el que Holaya considere según el nivel de riesgo que represente el Usuario, subsecuentemente dependiendo de cómo sea el comportamiento del Usuario, aunado al que éste concrete un buen historial con Holaya, a través de la renovación de sus préstamos, Holaya podrá autorizar al cliente montos mayores hasta llegar al máximo permitido de S/ 5,000.00 (CINCO MIL SOLES).</p>
      <p>Se hace el puntual señalamiento que hasta en tanto no se haya notificado dentro de la Aplicación o en la Página Web al Usuario por parte de Holaya la autorización del Préstamo, no se entenderá perfeccionado ningún otorgamiento del mismo préstamo, por dicho motivo el Usuario entiende que la aceptación de los presentes Términos y Condiciones no representa ofrecimiento formal alguno por parte de Holaya y no implica que el préstamo haya sido o que será autorizado.</p>
      <p>IX. DE LA OBLIGACIÓN DE PAGO, LOS PLAZOS PARA LIQUIDAR EL PRÉSTAMO, INTERESES ORDINARIOS E INTERESES MORATORIOS.</p>
      <p>Todo Usuario se le que le sea autorizado un Préstamo por parte de Holaya no importando la cantidad del mismo, tiene conocimiento de estos términos y condiciones, el Usuario al momento de aceptarlos está realizando un acto contractual de manera electrónica, en términos de los presentes términos y el Contrato del Anexo 1, en el cual, el mismo solicitante adquiere una deuda por el monto que le haya sido autorizado por Holaya, es así que deberá estar consciente que por el simple hecho de que Holaya le otorga dicho Préstamo se genera un interés sobre el total de saldos insolutos, ahora bien en caso de que el Usuario incumpla con la obligación de pago sobre el total del Préstamo se aumentara un interés moratorio por falta de pago, hasta que el Usuario decida finiquitar la deuda.</p>
      <p>Dado que la Aplicación se encuentra en un proceso de mejora continúa y constante actualización Holaya no puede garantizar que la misma esté disponible sin interrupciones y que siempre esté libre de errores, y por tanto, no se responsabiliza por posibles daños y perjuicios causados al Usuario por este concepto.</p>
      <p>X. MANEJO Y USO DE LA CUENTA NO AUTORIZADO POR EL USUARIO.</p>
      <p>En el entendido del numeral III de que el Usuario es responsable de la cuenta y de sus contraseñas, en caso de que estas fueran extraviadas, robadas o cualquier situación que se presentara en el momento presente o futuro, el Usuario tiene la obligación legal de notificar a Holaya en forma inmediata por escrito, vía electrónica o telefónica, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma, por lo tanto se advierte que al momento de que el Usuario realizo el registro de la Cuenta le queda terminantemente prohibido realizar venta, cesión o transferencia de la Cuenta.</p>
      <p>XI. RESTRICCIONES LEGALES EN MATERIA DE PREVENCIÓN DE LAVADO DE DINERO.</p>
      <p>El Usuario manifiesta y se obliga que los recursos obtenidos con el Préstamo no serán destinados a actividades ilícitas, se obliga a cubrir todas las cantidades (capital, intereses y comisiones) del Préstamo con recursos propios y de origen lícito, y producto de su actividad comercial, mercantil, profesional, mismas que no forman parte de todas aquellas actividades que la autoridad haya clasificado como ilícitas; que ningún tercero utilizará recursos provenientes de actividades ilícitas para operar con su consentimiento en los productos, cuentas, contratos o servicios donde opera y actúa; y, que no se realizarán transacciones u operaciones destinadas a favorecer actividades ilícitas.</p>
      <p>XII. ENVIÓ DE PUBLICIDAD Y MERCADOTECNIA.</p>
      <p>El Usuario otorga su consentimiento a Holaya para que por cualquier medio tecnológico que decida le haga llegar publicidad relacionada con los Servicios que ofrece y/o para que sus datos personales sean utilizados con fines mercadotécnicos y/o publicitarios.</p>
      <p>XII. MODIFICACIONES DE LOS TÉRMINOS Y CONDICIONES.</p>
      <p>Holaya podrá modificar los Términos y Condiciones en cualquier momento, haciendo públicos en la Aplicación o en la Página Web losTtérminos modificados.</p>
      <p>XIII. PROPIEDAD INTELECTUAL.</p>
      <p>El Usuario no adquirirá bajo ninguna circunstancia licencia o derecho alguno sobre la propiedad intelectual de Holaya por el mero uso de su App, la Página Web o de sus servicios; por lo tanto, su uso no podrá ser considerado como una autorización tácita o expresa para utilizar los sistemas y contenidos con algún fin diverso a los contemplados por el presente documento.</p>
      <p>El Usuario reconoce y acepta que la Aplicación, la Página Web y cualquier software que sea necesario para permitirle acceder y usar su cuenta para solicitar y recibir los servicios de Holaya, contienen información confidencial de Holaya y/o de terceros, que se encuentra protegida por las leyes de propiedad intelectual y otras leyes aplicables, asimismo, el usuario reconoce que el contenido que consta en la Aplicación y la Página Web de Holaya, son de la titularidad y propiedad exclusiva de Holaya y por consecuencia sus derechos de uso y explotación que incluyen mas no se limitan a su publicación, reproducción, divulgación, transformación y distribución le corresponden únicamente a Holaya y están protegidas por las normas nacionales e internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales.</p>
      <p>Holaya es titular de la propiedad intelectual de la Aplicación, los cuales incluyen de manera enunciativa y no limitativa:</p>
      <p>(i) el código fuente;</p>
      <p>(ii) el código objeto;</p>
      <p>(iii) marcas;</p>
      <p>(iv) nombres comerciales;</p>
      <p>(v) diseños;</p>
      <p>(vi) imágenes;</p>
      <p>(vii) vídeos;</p>
      <p>(viii) fotografías;</p>
      <p>(ix) lemas o slogans; y</p>
      <p>(x) finalmente cualquier creación que sea producto de la originalidad y se encuentra protegida por la Decisión 486 de la Comunidad Andina, el Decreto Legislativo 1075, la Decisión 351 de la Comunidad Andina, el Decreto legislativo 822 y demás Tratados internacionales y normativa aplicable. Por medio de los presentes Términos, la descarga o uso de la Aplicación Holaya no transfiere ningún derecho de propiedad intelectual a los Usuarios.</p>
      <p>Cualquier contravención a lo anteriormente expuesto o a la legislación aplicable en materia de propiedad intelectual, industrial, derechos de autor será considerada y perseguida como un delito. En consecuencia, el usuario entiende que le queda prohibido dar uso indebido, así como reproducir total o parcialmente dichos sistemas y contenidos.</p>
      <p>XIV. CONTACTO.</p>
      <p>Holaya estará a disposición del usuario en su domicilio localizado en Lima, Perú correo electrónico holayaapp@gmail.com, en un horario de 9:00 a 17:00 horas de lunes a viernes.</p>
      <p>XV. COSTOS POR SERVICIOS ASOCIADOS.</p>
      <p>El Usuario entiende y acepta que pueden existir otros costos asociados al servicio de Préstamo brindado por Holaya, los cuales incluyen, y no se limitan a, costo de transferencia de fondos según la entidad financiera elegida para recibir los fondos del Préstamo solicitado, y costos de recaudación según la entidad financiera elegida para pagar las cuotas.</p>
      <p>Holaya hará todo lo posible para reducir estos costos, ya que dependen de las condiciones comerciales de las entidades financieras externas que cobran de forma independiente por sus servicios. Si las tarifas se actualizan, Holaya notificará oportunamente a los usuarios de estas tarifas relevantes en la APP y Página Web.</p>
      <p>XV. CESIÓN.</p>
      <p>Holaya podrá, sin autorización del Usuario, ceder los derechos y obligaciones contenidas en estos Términos a cualquier tercero. El Usuario no podrá ceder sus derechos ni obligaciones.</p>
      <p>XVI. TÍTULOS.</p>
      <p>Los encabezados y sub-encabezados aquí contenidos, existen para conveniencia exclusivamente y no tienen efectos legales o contractuales.</p>
      <p>XVII. JURISDICCIÓN Y LEGISLACIÓN APLICABLE.</p>
      <p>Para todo lo relativo a la interpretación, cumplimiento y ejecución de las obligaciones derivadas de los presentes Términos y Condiciones, las Partes se someten a las Leyes Vigentes y a la jurisdicción de la República de Perú. Asimismo, para la resolución de cualquier controversia que se origine con motivo del mismo, las Partes se someten expresamente a la jurisdicción de los tribunales competentes en la ciudad de Lima, renunciando desde ahora a cualquier otra jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros o por la ubicación de sus bienes.</p>
      <p>ANEXO 1: CONTRATO DE MUTUO DINERARIO</p>
      <p>En caso un Usuario solicita un Préstamo y este es aprobado, se configura el presente Contrato de Mutuo Dinerario, que celebran, de una parte:</p>
      <p>El Usuario cuyo nombre, documento de identificación, y domicilio ha sido declarado en la Aplicación y/o Página Web de Holaya, participa por derecho propio denominándosele en los sucesivo como el MUTUATARIO, y de la otra parte; Holaya, a quien en lo sucesivo se denominará el MUTUANTE.</p>
      <p>El presente contrato se celebra bajo (i) los Términos y Condiciones señalados en la Aplicación de Holaya y/o en la Página Web https://www.holaya.net/#/agreement y (ii) las siguientes:</p>
      <p>DECLARACIONES</p>
      <p>1. Declara el MUTUATARIO ser una persona natural que ha solicitado al MUTUANTE un préstamo de dinero a ser devuelto con intereses compensatorios de acuerdo a lo estipulado en este contrato.</p>
      <p>2. Declara el MUTUATARIO qué manifestó su aceptación a los Términos y Condiciones; y la Pólitica de Privacidad y Protección De Datos Personales contenidos en la Página Web y la Aplicación de Holaya.</p>
      <p>3. El MUTUANTE y el MUTUARIO, manifiestan su conformidad de referirse a los significados precisados en los Términos y Condiciones; y la Pólitica de Privacidad y Protección De Datos Personales contenidos en la Página Web y la Aplicación de Holaya.</p>
      <p>4. Tomando en cuenta lo solicitado por el MUTUATARIO, por medio del presente documento el MUTUANTE, manifiesta su interés en otorgar el préstamo dinerario que requiere el MUTUATARIO cuyas condiciones serán estipuladas en las cláusulas siguientes.</p>
      <p>CLÁUSULAS </p>
      <p>Primera.- Por el presente Contrato el MUTUANTE entrega, en calidad de MUTUO, a favor del MUTUATARIO, la suma de dinero acordado y que se refleja en la Aplicación y/o Página Web de Holaya; con el objeto de financiar las actividades del EL MUTUATARIO.</p>
      <p>Segunda.- La suma materia de mutuo será depositada a la cuenta que el MUTUATARIO indique en la Aplicación y/o Página Web de Holaya. </p>
      <p>Tercera.- El interés compensatorio será aquel aceptado por el MUTUATARIO en la Aplicación y/o Página Web de Holaya, el cual podrá ser indicado en forma porcentual relativa o de forma absoluta como valor a pagar.</p>
      <p>Cuarta.- El presente contrato tiene validez hasta que el Préstamo haya sido pagado tanto su valor de capital principal como intereses, costos de servicios asociados, moras y/o penalidades.</p>
      <p>Quinta.- El pago del capital más los intereses, costos de servicios asociados, moras y/o penalidades será realizado a través de abono en la cuenta indicada por la Aplicación y/o Página Web de Holaya. </p>
      <p>Sexta.- La falta de pago dará derecho al MUTUANTE a las acciones listadas a continuación, el MUTUATARIO no tendrá derecho a ninguna indemnización o compensación alguna por las medidas tomadas por el MUTUANTE </p>
      <p>1. Ejecutar distintas medidas en el Dispositivo Móvil del MUTUATARIO de acuerdo a los permisos otorgados, incluyendo y no limitados a: bloqueo de la pantalla del Dispositivo Móvil o inhabilitación de servicios normales.  </p>
      <p>2. Denunciar al MUTUATARIO por los atrasos de pagos. </p>
      <p>3. Dar inicio a las acciones judiciales con la finalidad de cobrar la suma adeudada quedando el MUTUATARIO constituido en mora sin necesidad de requerimiento alguno. </p>
      <p>4. Las partes acuerdan que, en caso de incumplimiento en el pago del capital, el interés costos de servicios asociados, moras y/o penalidades, la suma adeudada podrá generar intereses moratorios de manera adicional al interés compensatorio pactado hasta la cancelación total del MUTUO. Estos intereses moratorios serán indicados en la Aplicación y/o Página Web de Holaya. </p>
      <p>Séptima.- La invalidez, ineficacia o inexigibilidad de cualquier disposición de este contrato no perjudicará la validez, eficacia o exigibilidad de las demás disposiciones, las cuales permanecerán con plena vigencia y efecto. Las partes pondrán todo de su parte para alcanzar los objetivos de la disposición nula a través de una estipulación nueva legalmente válida. </p>
      <p>Octava.- El MUTUANTE podrá, sin autorización del Usuario, ceder los derechos y obligaciones contenidas en este Contrato a cualquier tercero.</p>
      <p>El MUTUARIO no podrá ceder sus derechos ni obligaciones respecto a este Contrato.</p>
      <p>Novena.- Las partes acuerdan que el presente Contrato será regido por la Ley peruana. Asimismo, todo aquello que no esté regulado en este Contrato, se regirá por las normas pertinentes del Código Civil.  </p>
      <p>Décima.- Las partes acuerdan que cualquier controversia, diferencia o reclamación que se produzca entre ellas relativa a la interpretación, ejecución, resolución, rescisión, eficacia, validez u otro asunto vinculado al presente contrato, o por cualquier otro motivo o circunstancia relacionada directa o indirectamente con éste y con los que por causa de este Contrato se susciten se someterán a la competencia de los jueces y tribunales del distrito judicial de Lima  </p>
    </div>
  )
}

export default Agreement;