import React from "react";
import RedDotTitle from "../../../components/RedDotTitle";

import './sectionFourth.scss';

const SectionFourth = () => {
  return (
    <div className="section-fourth">
      <RedDotTitle className="title" dotSize="large">Comentarios de los clientes</RedDotTitle>
      <div className="block-container">
        <div className="block">
          <RedDotTitle className="block-title">Diana</RedDotTitle>
          <p className="content">Excelente aplicación que me permite realizar transferencias de forma fácil y rápida,
            con la diferencia de que el proceso de aceptación es tan sencillo que no me lleva ni 10 minutos.</p>
          <i className="icon"/>
        </div>
        <div className="block">
          <RedDotTitle className="block-title">Nicolle</RedDotTitle>
          <p className="content">Gran experiencia de préstamo, rápido, seguro, aplicación es fácil de usar, opciones de
            pago aceptan múltiples bancos, perfecto, me libré de un gran préstamo.</p>
          <i className="icon"/>
        </div>
        <div className="block">
          <RedDotTitle className="block-title">Jorge</RedDotTitle>
          <p className="content">Si necesitas ayuda para un préstamo, te recomiendo que descargues y utilices esta
            aplicación. Es muy fácil y te ayudarán paso a paso. Yo lo he hecho y realmente lo recomiendo, solo espero y
            realmente recibo mi préstamo, no espero tanto como otras aplicaciones.</p>
          <i className="icon"/>
        </div>
      </div>

    </div>
  )
}

export default SectionFourth;