import React from "react";
import SectionFirst from "./components/SectionFirst";
import SectionSecond from "./components/SectionSecond";
import SectionThird from "./components/SectionThird";
import SectionFourth from "./components/SectionFourth";
import SectionFifth from "./components/SectionFifth";

const Home = () => {
  return (
    <div className="holaya-home-container">
      <SectionFirst />
      <SectionSecond />
      <SectionThird />
      <SectionFourth />
      <SectionFifth />
    </div>
  )
}

export default Home;