import Home from "../pages/Home";
import Agreement from "../pages/Agreement";
import Privacy from "../pages/Privacy";
import NotFound from "../pages/NotFound";

interface IRoutes {
  path: string,
  element: any,
}

const routes: IRoutes[] = [
  {
    path: '*',
    element: NotFound,
  },
  {
    path: '/',
    element: Home,
  },
  {
    path: '/agreement',
    element: Agreement,
  },
  {
    path: '/privacy',
    element: Privacy,
  },

]

export default routes;