import React from "react";
import { Link } from 'react-router-dom';
import RedDotTitle from "../../../components/RedDotTitle";
import {scrollToTop, EMAIL, TIME, APP_LINK, WHATS_APP_LINK, WHATS_APP} from "../../../utils";
import './sectionFifth.scss';

const TITLE = <RedDotTitle className="title">Contacto</RedDotTitle>;

const SectionFifth = () => {
  const onScrollTop = () => scrollToTop();

  return (
    <div className="section-fifth">
      <div className="logo"/>
      {
        isMobile ?
          <div className="contact-m">
            {TITLE}
            <div className="list-m">
              <div className="item">
                <div className="icon icon-1"/>
                <span>{EMAIL}</span>
              </div>
              <div className="item">
                <div className="icon icon-2"/>
                <Link to={WHATS_APP_LINK}>{WHATS_APP}</Link>
              </div>
            </div>
            <div className="list-m">
              {/*<div className="item">*/}
              {/*  <div className="icon icon-3"/>*/}
              {/*  <a href={`tel:${PHONE}`}>{PHONE}</a>*/}
              {/*</div>*/}
              <div className="item">
                <div className="icon icon-4"/>
                <span>{TIME}</span>
              </div>
            </div>
            <div className="operate-area">
              <div className="links">
                <p className="link top" onClick={onScrollTop}>Inicio</p>
                <Link to="/privacy" className="link">Política de privacidad</Link>
                <Link to="/agreement" className="link">Acuerdo de usuario</Link>
              </div>
              <a href={APP_LINK}>
                <div className="button">Descargar ya</div>
              </a>
            </div>
          </div> :
          <div className="contact">
            {TITLE}
            <div className="list">
              <div className="item">
                <div className="icon icon-1"/>
                <span>{EMAIL}</span>
              </div>
              <p className="link top" onClick={onScrollTop}>Inicio</p>
            </div>
            <div className="list">
              <div className="item">
                <div className="icon icon-2"/>
                <Link to={WHATS_APP_LINK}>{WHATS_APP}</Link>
              </div>
              <Link to="/privacy" className="link">Política de privacidad</Link>
            </div>
            <div className="list">
              <div className="item">
                <div className="icon icon-4"/>
                <span>{TIME}</span>
              </div>
              <Link to="/agreement" className="link">Acuerdo de usuario</Link>
            </div>
            <div className="list">
              <div className="item">
              </div>
              <a href={APP_LINK}>
                <div className="button">Descargar ya</div>
              </a>
            </div>
          </div>
      }
    </div>
  )
}

export default SectionFifth;
